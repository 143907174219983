import { Controller } from "@hotwired/stimulus";
import { getMetaContent, flashNow } from "./helpers";

// Connects to data-controller="easter-egg"
export default class extends Controller {
  SHOW_CONTROLS_START_TIME = 33;
  SHOW_CONTROLS_END_TIME = 45;

  static targets = [
    "controls", // Container for the controls
    "modal", // Modal container
    "openButton", // Button to open the modal
    "videoPlayer", // Video player
    "inputtedCode", // Container to visually show the inputted code
  ];

  static values = {
    codeLength: Number,
    verifyPath: String,
  };

  connect() {
    this.inputtedCode = [];
    this.openButtonTarget.addEventListener("click", this.openModal);
    this.videoPlayerTarget.addEventListener("timeupdate", this.onTimeUpdate);
  }

  disconnect() {
    this.openButtonTarget.removeEventListener("click", this.openModal);
    this.videoPlayerTarget.removeEventListener("timeupdate", this.onTimeUpdate);
  }

  clearInputtedCode() {
    this.inputtedCode = [];
    this.inputtedCodeTarget.innerHTML = "";
  }

  openModal = () => {
    this.clearInputtedCode();
    this.modalTarget.setAttribute("aria-hidden", "false");
    this.openButtonTarget.setAttribute("aria-expanded", "true");
    window.addEventListener("keyup", this.onWindowKeyup);
    window.addEventListener("click", this.onWindowClick);
    this.videoPlayerTarget.play();
  };

  closeModal = () => {
    this.modalTarget.setAttribute("aria-hidden", "true");
    this.openButtonTarget.setAttribute("aria-expanded", "false");
    window.removeEventListener("keyup", this.onWindowKeyup);
    window.removeEventListener("click", this.onWindowClick);
    this.videoPlayerTarget.pause();
    this.videoPlayerTarget.currentTime = 0;
  };

  onWindowKeyup = (event) => {
    if (event.key !== "Escape") return;
    this.closeModal();
  };

  onWindowClick = (event) => {
    if (event.target !== this.modalTarget) return;
    this.closeModal();
  };

  getDisplayedInputtedCode(value) {
    switch (value) {
      case "UP":
        return "↑";
      case "DOWN":
        return "↓";
      case "LEFT":
        return "←";
      case "RIGHT":
        return "→";
      default:
        return value;
    }
  }

  appendInputtedCode = (value) => {
    const inputtedCodeItem = document.createElement("div");
    inputtedCodeItem.classList.add("inputted-code-item");
    inputtedCodeItem.textContent = this.getDisplayedInputtedCode(value);
    this.inputtedCodeTarget.appendChild(inputtedCodeItem);
  };

  submitCode = async () => {
    if (this.inputtedCode.length === 0) return;

    const endpoint = this.verifyPathValue;
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
        "Content-Type": "application/json",
        "X-CSRF-Token": getMetaContent("csrf-token"),
      },
      body: JSON.stringify({
        inputted_code: this.inputtedCode.join(" "),
      }),
    });

    if (response.status === 200) {
      this.closeModal();
      return response.text().then((html) => Turbo.renderStreamMessage(html));
    } else if (response.status === 422) {
      flashNow("alert", "Incorrect Code");
      this.clearInputtedCode();
    } else {
      flashNow("alert", "Something went wrong, please try again later or contact support.");
    }
  };

  inputCode = (event) => {
    const value = event.target.dataset.value;
    this.inputtedCode.push(value);
    this.appendInputtedCode(value);

    if (this.inputtedCode.length === this.codeLengthValue + 1) {
      this.submitCode();
    }
  };

  showControls = () => {
    this.controlsTarget.classList.remove("!hidden");
  };

  hideControls = () => {
    this.controlsTarget.classList.add("!hidden");
  };

  onTimeUpdate = () => {
    const currentTime = this.videoPlayerTarget.currentTime;
    const duration = this.videoPlayerTarget.duration;
    const percentage = (currentTime / duration) * 100;

    if (currentTime >= this.SHOW_CONTROLS_START_TIME && currentTime <= this.SHOW_CONTROLS_END_TIME) {
      this.showControls();
    } else {
      this.hideControls();
    }

    if (percentage === 100) {
      this.closeModal();
    }
  };
}
